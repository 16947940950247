import Vue from 'vue';
import {AES, enc} from 'crypto-js';
import VueRouter from 'vue-router';
import store from '../store/index'

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: ['Guest', 'Admin', 'Installer'],
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      requiresAuth: true,
      authorizedRoles: ['Guest', 'Admin', 'Installer'],
    },
  },
  {
    path: '/entities/devices',
    name: 'Devices',
    component: () => import('../views/entities/Devices.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Guest', 'Admin', 'Installer'] },
  },
  {
    path: '/entities/inventory',
    name: 'Inventory',
    component: () => import('../views/entities/Inventory.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/entities/newentity',
    name: 'NewEntity',
    component: () => import('../views/entities/NewEntity.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/entities/newiotentity',
    name: 'NewIotEntity',
    component: () => import('../views/entities/NewIotEntity.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/entities/editiotentity',
    name: 'EditIotEntity',
    component: () => import('../views/entities/EditIotEntity.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/iotagent',
    name: 'IotAgent',
    component: () => import('../views/iotagent/IotAgent.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/iotagent/newservice',
    name: 'NewService',
    component: () => import('../views/iotagent/NewService.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/iotagent/newdevice',
    name: 'NewDevice',
    component: () => import('../views/iotagent/NewDevice.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/iotagent/editdevice',
    name: 'EditDevice',
    component: () => import('../views/iotagent/EditDevice.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin', 'Installer'] },
  },
  {
    path: '/iotagent/newsuscription',
    name: 'NewSuscription',
    component: () => import('../views/iotagent/NewSuscription.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/iotagent/editsuscription',
    name: 'EditSuscription',
    component: () => import('../views/iotagent/EditSuscription.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Guest', 'Admin', 'Installer'] },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/users/Users.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  {
    path: '/users/newuser',
    name: 'NewUser',
    component: () => import('../views/users/NewUser.vue'),
    meta: { requiresAuth: true, authorizedRoles: ['Admin'] },
  },
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //console.log(store.getters.signedIn);
  
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    var token = sessionStorage.getItem('access_token');
    var user = sessionStorage.getItem('user');
    var service = JSON.parse(sessionStorage.getItem('fiware_service'))
    if (token && user && service) {
    store.commit('setFiwareService', service);
    const decryptedToken = AES.decrypt(sessionStorage.getItem('access_token'),process.env.VUE_APP_CRYPTO_KEY);
    const decryptedUser = AES.decrypt(sessionStorage.getItem('user'),process.env.VUE_APP_CRYPTO_KEY);
    token = JSON.parse(decryptedToken.toString(enc.Utf8));
    user = JSON.parse(decryptedUser.toString(enc.Utf8));
    if (user && token) {
      let expirationDate = new Date(token.expirationDate)
      if (expirationDate > new Date()) { //token aaun valido sigue en sesion
        if (!to.meta.authorizedRoles.includes(user.roles[0].name)) {
          next({
            path: '/home',
          });
        } else {
          next();
        }
      } else { //token expiro se borra sesion y se vuelve al login
        store.dispatch('logOut');
        next({
          path: '/',
        });
      }
  
    }
    else { // no existe token no hay sesion
      store.dispatch('logOut');
      next({
        path: '/',
      });
    }
  }
    else { // no existe token no hay sesion
      store.dispatch('logOut');
      next({
        path: '/',
      });
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
