<template>
  <v-footer app dark>
    <span class="mx-auto">
      &copy; 2024
      <a
        class="green--text text--darken-1"
        style="text-decoration:none;"
        href="https://www.siot-ingenieria.com.co/"
        target="_blank"
      >Siot Ingeniería SAS</a>
    </span>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>