<template>
  <div>
    <v-navigation-drawer v-model="drawer" app fixed temporary dark>
      <v-img :aspect-ratio="16 / 9" src="/images/river.jpeg" height="130">
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="/images/profile.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ getUser.username }} ({{ getUser.roles[0].name }})
            </v-list-item-title>
            <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-img>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" :to="item.path" exact>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item link @click.prevent="onSubmit()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home' }">
          <v-img
            alt="Siot Logo"
            class="shrink mx-auto"
            contain
            src="/images/app_logo.png"
            transition="scale-transition"
            max-width="80%"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <v-btn icon @click.prevent="onSubmit()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  data: () => ({
    drawer: null,
    items: [
      { title: "Mapa", icon: "mdi-map-marker-radius", path: "/home" },
      { title: "Panel Dispositivos", icon: "mdi-router-wireless", path: "/entities/devices" },
      { title: "Gestión Entidades", icon: "mdi-office-building-marker", path: "/entities/inventory" },
      { title: "Gestión Agente IoT", icon: "mdi-key-wireless", path: "/iotagent" },
      { title: "Reportes", icon: "mdi-cloud-download", path: "/reports" },
      //{ title: "Usuarios", icon: "mdi-account-group-outline", path: "/users" },
      { title: "Configuración", icon: "mdi-application-cog-outline", path: "/settings" },
      { title: "Mi Cuenta", icon: "mdi-account", path: "/account" },
      { title: "Acerca de", icon: "mdi-help-box", path: "/about" },
    ],
  }),
  computed: mapGetters(["getUser"]),
  methods: {
    ...mapActions(["logOut"]),
    onSubmit() {
      this.logOut();
      this.$router.push("/");
    },
  },
};
</script>
