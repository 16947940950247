<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="green" dark flat>
            <v-toolbar-title>Iniciar Sesión</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="onSubmit()">
            <v-card-text>
              <v-text-field
                label="Email"
                name="username"
                v-model="login.username"
                prepend-icon="mdi-account"
                type="text"
                :rules="[rules.required, rules.email]"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Contraseña"
                v-model="login.password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[rules.required]"
              ></v-text-field>
              <v-select
                v-model="select"
                :items="items"
                :rules="[v => !!v || 'Requerido']"
                label="Repositorio"
                prepend-icon="mmdi-database"
                required
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="green" dark>Iniciar Sesión</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" align="center" justify="center">
        <div>
          <h3>Cithings Net v1.0</h3>
          <p>
            IoT Platform for Cities “Powered by FIWARE”
          </p>
        </div>
        <div>
          <a href="https://www.siot-ingenieria.com.co/" target="_blank">
          <v-img
            src="/images/siot_logo.png"
            alt="Siot Logo"
            width="80%"
            max-width="342"
          ></v-img>
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    login: {
      username: null,
      password: null,
    },
    select: null,
    items: ['Dev','Sogamoso','Aguazul','Guaitarilla'],
    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "e-mail invalido";
      },
    },
  }),
  computed: mapGetters(["getUser", "signedIn", "getError"]),
  watch: {
    select: function (newValue) {
          this.fetchFiwareService(newValue)
      },
  },
  methods: {
    ...mapActions(["authUser","fetchFiwareService"]),
    onSubmit() {
      console.log("submit");
      if (this.login.username && this.login.password) {
        this.authUser(this.login)
          .then((success) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: `Bienvenido ${this.getUser.username}!`,
              alertClass: "success",
            });
            this.$router.push("/home");
          })
          .catch((error) => {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: "Usuario y/o contraseña invalido.",
              alertClass: "error",
            });
          });
        // if (this.getError) {
        //   this.$store.commit("SET_NOTIFICATION", {
        //     display: true,
        //     text: this.getError,
        //     alertClass: "error",
        //   });
        // } else {
        //   this.$store.commit("SET_NOTIFICATION", {
        //     display: true,
        //     text: "Iniciando sesión...",
        //     alertClass: "success",
        //   });
        // }
      }
    },
  },
};
</script>
