<template>
  <v-snackbar
    :color="NOTIFICATION.class"
    v-model="snackbar"
    bottom
    right
    outlined
    :timeout="NOTIFICATION.timeout"
  >
    {{ NOTIFICATION.text }}
    <template v-slot:action="{ attrs }">
    <v-btn v-bind="attrs" dark @click.prevent="snackbar = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    </template>
  </v-snackbar>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "Notification",
  computed: {
    ...mapGetters(["NOTIFICATION"]),
    snackbar: {
      get() {
        return this.$store.getters.NOTIFICATION.display;
      },
      set(value) {
        this.$store.commit("SET_NOTIFICATION", {
          display: false,
        });
      },
    },
  },
};
</script>