<template>
  <v-app id="cithings">
    <template v-if="!signedIn">
      <v-app-bar app clipped-left dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <router-link :to="{ name: 'Home' }">
            <v-img
              alt="Siot Logo"
              class="shrink mx-auto"
              contain
              src="/images/app_logo.png"
              transition="scale-transition"
              max-width="80%"
            />
          </router-link>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <Login />
        </v-container>
      </v-main>
      <Footer />
      <Notification />
    </template>
    <template v-if="signedIn">
      <Header />

      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>

      <Footer />
      <Notification />
    </template>
  </v-app>
</template>

<script>
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import Login from "@/components/Login.vue";
import Notification from "@/components/Notification.vue";

import { mapGetters } from "vuex";
import authHeader from './utils/auth-header';

export default {
  name: "App",
  computed: mapGetters(["signedIn"]),
  components: {
    Header,
    Footer,
    Login,
    Notification,
  },
  data: () => ({
    drawer: null,
  }),
  created() {
    //console.log('created');
    var {token, user} = authHeader();
    if (user && token) {
      this.$store.commit("setSignedIn", true);
      this.$store.commit("setUser", user);
    }
  },
};
</script>
