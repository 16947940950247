import axios from 'axios';
import authHeader from '@/utils/auth-header';


const state = {
  deviceTypeCard: 'THSensor',  //used for select device specific status card
  provisionedDevice: null, //list device provisioned on IoT Agent for editpage
  provisionedDevices: [], //list all devices provisioned on IoT Agent
  provisionedServices: [], //list all services provisioned on IoT Agent
  devices: [], //list all devices info on context brocker
  devicesWithEntity: [], //list all devices info with parent entity info on context broker
  entities: [], //list all entities info
  iotEntity: null, //list iot entity 
  iotEntities: [], //list all iot entities info
  iotSubscription: null, //list IoT subscription info for editpage
  iotSubscriptions: [], //list all IoT subscriptions info
  devicesCurrentData: [], //list all devices last register data
  deviceCurrentData: null, //list last selected device data
  deviceData: [], //list timestamp range selected device data
  chartSeries:[], //list series for chart selected device data
  serviceCheck: null,
  fiwareServices: {
    Dev: {
      FIWARE_SERVICE: 'dev',
      FIWARE_SERVICEPATH: '/',
      IOT_FIWARE_SERVICE: 'openiot',
      IOT_FIWARE_SERVICEPATH: '/',
  },
  Sogamoso: {
      FIWARE_SERVICE: 'sat_sogamoso',
      FIWARE_SERVICEPATH: '/',
      IOT_FIWARE_SERVICE: 'sat_sogamoso_iot',
      IOT_FIWARE_SERVICEPATH: '/',
  },
  Aguazul: {
      FIWARE_SERVICE: 'sat_aguazul',
      FIWARE_SERVICEPATH: '/',
      IOT_FIWARE_SERVICE: 'sat_aguazul_iot',
      IOT_FIWARE_SERVICEPATH: '/',
  },
  Guaitarilla: {
      FIWARE_SERVICE: 'sat_guaitarilla',
      FIWARE_SERVICEPATH: '/',
      IOT_FIWARE_SERVICE: 'sat_guaitarilla_iot',
      IOT_FIWARE_SERVICEPATH: '/',
  },
  },
  fiwareService: {},
};

const getters = {
  getDeviceTypeCard: (state) => state.deviceTypeCard,
  getProvisionedDevice: (state) => state.provisionedDevice,
  getProvisionedDevices: (state) => state.provisionedDevices,
  getProvisionedServices: (state) => state.provisionedServices,
  getContextDevices: (state) => state.devices,
  getContextDevicesWithEntity: (state) => state.devicesWithEntity,
  getEntities: (state) => state.entities,
  getIoTEntity: (state) => state.iotEntity,
  getIoTEntities: (state) => state.iotEntities,
  getIoTSubscriptions: (state) => state.iotSubscriptions,
  getIoTSubscription: (state) => state.iotSubscription,
  getDevicesCurrentData: (state) => state.devicesCurrentData,
  getDeviceCurrentData: (state) => state.deviceCurrentData,
  getDeviceData: (state) => state.deviceData,
  getChartSeries: (state) => state.chartSeries,
  getServiceCheck: (state) => state.serviceCheck,
  getFiwareServices: (state) => state.fiwareServices,
  getFiwareService: (state) => state.fiwareService
};

const actions = {
  fetchFiwareService({ commit, getters }, service) {
    let selectedService = getters.getFiwareServices[service];
    //console.log(selectedService);
    sessionStorage.setItem('fiware_service', JSON.stringify(selectedService));
    commit('setFiwareService', selectedService);
  },
  registerEntity({ commit, getters }, entity) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(entity);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, entity, {
          headers: {
            'Content-Type': 'application/json',
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEntity({ commit, getters }, entity) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(entity);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      let body = [entity]
      axios
        .post(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entityOperations/upsert`, body, {
          params: {
            options: 'update',
          },
          headers: {
            'Content-Type': 'application/json',
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEntity({ commit, getters }, entity_id) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(entity_id);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${entity_id}`, {
          headers: {
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerIoTEntity({ commit, getters }, entity) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(entity);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, entity, {
          headers: {
            'Content-Type': 'application/json',
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateIoTEntity({ commit, getters }, entity) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(entity);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      let body = [entity]
      axios
        .post(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entityOperations/upsert`, body, {
          params: {
            //options: 'update', // indicates we will not remove existing attributes if they already 
            options: 'replace', // this means we will overwrite existing entities
          },
          headers: {
            'Content-Type': 'application/json',
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteIoTEntity({ commit, getters }, entity_id) {
    //entity.owner.push = authHeader().organizations.name;
    console.log(entity_id);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${entity_id}`, {
          headers: {
            'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, 
  deleteDeviceEntity({ commit, getters }, entity_id) {
    //entity.owner.push = authHeader().organizations.name;
    console.log(entity_id);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${entity_id}`, {
          headers: {
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerDevice({ commit, getters }, device) {
    //device.owner.push = authHeader().organizations.name;
    //console.log(device);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/devices`, device, {
          headers: {
            'Content-Type': 'application/json',
            'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDevice({ commit, getters }, device) {
    //attrs.owner.push = authHeader().organizations.name;
    //console.log(device);
    let selectedService = getters.getFiwareService;
    let {device_id, ...attrs} = device;
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/devices/${device_id}`, attrs, {
          headers: {
            'Content-Type': 'application/json',
            'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDevice({ commit, getters }, device_id) {
    //service.owner.push = authHeader().organizations.name;
    //console.log(device_id);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/devices/${device_id}`, {
          headers: {
            'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerServiceGroup({ commit, getters }, service) {
    //service.owner.push = authHeader().organizations.name;
    //console.log(service);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/services`, service, {
          headers: {
            'Content-Type': 'application/json',
            'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteServiceGroup({ commit, getters }, service) {
    //service.owner.push = authHeader().organizations.name;
    //console.log(service);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/services`, {
          params: {
            resource: service.resource,
            apikey: service.apikey
          },
          headers: {
            'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchContextDevicesAndParentEntity({ commit, getters }) {
    let res;
    let selectedService = getters.getFiwareService;
    try {
      res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, {
        params: {
          options: 'keyValues',
          type: 'THSensor,DeviceMeasurement,temperature,WeatherObserved',
          //attrs: 'dateCreated,dateModified,*',
        },
        headers: {
          'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data)
    } catch (error) {
      console.log(error);
    }
    Promise.all(res.data.filter(item => {
      if (item.controlledAsset != undefined || item.refPointOfInterest != undefined) { // filtra posibles casos de dispositivos registrados que no tengan un entity padre establecido
        return item;
      }
    }).map(async item => {
      if (item.dateObserved == undefined) {
        if (item.dateModified) {
          let updatedAt  = new Date(item.dateModified['@value']);
        item.updatedAt  = updatedAt.toLocaleDateString();
        //console.log(item.createdAt);

        }
      }
      else{
        let updatedAt = new Date(item.dateObserved);
        item.updatedAt = updatedAt.toLocaleString();
      }
      if (item.deviceTech == undefined) {
      item.deviceTech = item.supportedProtocol;

      }
      
      let entity;
      try {
        if (item.controlledAsset != undefined) {
          entity = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${item.controlledAsset}`, {
            params: {
              options: 'keyValues',
              //type: 'Store',
              //attrs: 'dateCreated,dateModified,*',
            },
            headers: {
              'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
              'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
              'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
              'Accept':'application/ld+json',
              'X-Auth-Token': authHeader().token,
            },
          });
        } else {
          entity = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${item.refPointOfInterest}`, {
            params: {
              options: 'keyValues',
              //type: 'Store',
              //attrs: 'dateCreated,dateModified,*',
            },
            headers: {
              'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
              'fiware-servicepath': selectedService.FIWARE_SERVICEPATH || process.env.VUE_APP_FIWARE_SERVICEPATH,
              'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
              'Accept':'application/ld+json',
              'X-Auth-Token': authHeader().token,
            },
          });
        }
        
        
      } catch (error) {
        console.log(error);
      }

      if (entity) { //valida si existe la entidad puede que se haya eliminado y algun dispositivo aun haga referencia
        item.controlledAsset = entity.data;
      }
      else {
        item.controlledAsset = {
          id: "NA",
          type: "NA",
          address: {
            streetAddress: "NA",
            addressRegion: "NA",
            addressLocality: "NA",
            postalCode: "NA"
          },
          location: {
            type: "Point",
            coordinates: [null,null]
          },
          name: "NA",
        }
      }
      
      return item
    
    })).then(results => {
      // All the resolved promises returned from the map function.
      //console.log(results);
      commit('setContextDevicesWithEntity', results);
    
    })
  },
  async fetchContextIoTEntities({ commit, getters }) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, {
        params: {
          options: 'keyValues',
          type: 'THSensor,Device,DeviceMeasurement,WeatherObserved,AirQualityObserved,WaterObserved,NoiseLevelObserved',
          //attrs: 'dateCreated,dateModified,*',
        },
        headers: {
          'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.FIWARE_SERVICEPATH ||process.env.VUE_APP_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      let entityArray = res.data.map(item => {
        if (item.dateObserved == undefined) {
          if (item.dateModified){
            let updatedAt = new Date(item.dateModified['@value']);
            item.updatedAt = updatedAt.toLocaleString();
          //console.log(item.createdAt);
        }
        }
        else{
          let updatedAt = new Date(item.dateObserved);
          item.updatedAt = updatedAt.toLocaleString();
        }
        
        return item;
      });
      commit('setIotEntities', entityArray);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchContextDevices({ commit, getters }) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, {
        params: {
          options: 'keyValues',
          type: 'THSensor,Device,DeviceMeasurement,WeatherObserved,AirQualityObserved,WaterObserved,NoiseLevelObserved',
          //attrs: 'dateCreated,dateModified,*',
        },
        headers: {
          'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      let entityArray = res.data.map(item => {
        if (item.dateObserved) {
          let updatedAt  = new Date(item.dateObserved);
          item.updatedAt = updatedAt.toLocaleString();
        //console.log(item.dateObserved);
        }
        if (item.stationID == undefined) {
          item.stationID = item.serialNumber
        //console.log(item.stationID);
        }

        if (item.refDevice) {
          item.controlledAsset = item.refDevice
        //console.log(item.controlledAsset);
        }
        
        return item;
      });
      commit('setContextDevices', entityArray);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchProvisionedDevices({ commit, getters }) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/devices`, {
        params: {
          //options: 'keyValues',
        },
        headers: {
          'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      commit('setProvisionedDevices', res.data.devices);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchProvisionedServices({ commit, getters}) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/services`, {
        params: {
          //options: 'keyValues',
        },
        headers: {
          'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      commit('setProvisionedServices', res.data.services);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchEntities({ commit, getters }) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, {
        params: {
          type: 'Building,PointOfInterest',
          options: 'keyValues',
        },
        headers: {
          'NGSILD-Tenant': selectedService.FIWARE_SERVICE || process.env.VUE_APP_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.FIWARE_SERVICEPATH ||process.env.VUE_APP_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      let entityArray = res.data.map(item => {
        if (item.dateCreated) {
          let createdAt = new Date(item.dateCreated['@value']);
        item.createdAt = createdAt.toLocaleDateString();
        //console.log(item.createdAt);
        
        }
        return item;
      });
      commit('setEntities', entityArray);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchIoTSuscriptions({ commit, getters }) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/`, {
        headers: {
          'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'X-Auth-Token': authHeader().token,
        },
      });
      //console.log(res.data);
      let suscriptionsArray = res.data.map(item => {
        let lastNotification = new Date(item.notification.lastNotification);
        item.lastNotificationAt = lastNotification.toLocaleString();
        let lastSuccess = new Date(item.notification.lastSuccess);
        item.lastSuccessAt = lastSuccess.toLocaleString();
        //console.log(item.createdAt);
        return item;
      });
      commit('setIotSubscriptions', suscriptionsArray);
    } catch (error) {
      console.log(error);
    }
  },
  registerIoTSuscription({ commit, getters }, suscription) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(suscription);
    return new Promise((resolve, reject) => {
      let selectedService = getters.getFiwareService;
      axios
        .post(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/`, suscription, {
          headers: {
            'Content-Type': 'application/json',
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateIoTSuscription({ commit, getters }, suscription) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(suscription);
    let selectedService = getters.getFiwareService;
    let {id,entities,watchedAttributes, ...attrs} = suscription;
    console.log(attrs);
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/${id}`, attrs, {
          headers: {
            'content-type': 'application/json',
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startIoTSuscription({ commit, getters }, suscription_id) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(suscription_id);
    let selectedService = getters.getFiwareService;

    let attrs = {
      "status": "active",
      "isActive": true,
    };

    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/${suscription_id}`, attrs, {
          headers: {
            'content-type': 'application/json',
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  pauseIoTSuscription({ commit, getters }, suscription_id) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(suscription_id);
    let selectedService = getters.getFiwareService;

    let attrs = {
      //"status": "active",
      "isActive": false,
    };

    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/${suscription_id}`, attrs, {
          headers: {
            'content-type': 'application/json',
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteIoTSuscriptions({ commit, getters }, suscription_id) {
    //entity.owner.push = authHeader().organizations.name;
    //console.log(suscription_id);
    let selectedService = getters.getFiwareService;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/subscriptions/${suscription_id}`, {
          headers: {
            'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
            'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
            'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
            'X-Auth-Token': authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 204) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchDevicesCurrentData({ commit, getters}) {
    try {
      let selectedService = getters.getFiwareService;
      //console.log(selectedService.IOT_FIWARE_SERVICE)
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/`, {
        params: {
          options: 'keyValues',
          type: 'THSensor,DeviceMeasurement,temperature,WeatherObserved'
        },
        headers: {
          'X-Auth-Token': authHeader().token,
          'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
        },
      });
      //console.log(res.data);
      const dataArray = res.data
      .filter(item => {
        if (item.location != undefined) { // filtra posibles casos de dispositivos registrados que no tengan un location establecido
          return item;
        }
      })
      .map(item => {
        item.location.coordinates =item.location.coordinates.reverse(); //el orden de las coordenadas de geojson debe invertirse aca para poderlo ver de forma correcta en el mapa
        return item;
      })
      commit('setDevicesCurrentData', dataArray);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchDeviceCurrentData({ commit, getters }, payload) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/ngsi-ld/v1/entities/${payload.dev_id}`, {
        params: {
          options: 'keyValues',
          //type: payload.type
        },
        headers: {
          'X-Auth-Token': authHeader().token,
          'NGSILD-Tenant': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'Link':'<http://context/ngsi-context.jsonld>; rel="http://www.w3.org/ns/json-ld#context"; type="application/ld+json"',
          'Accept':'application/ld+json',
        },
      });
      //console.log(res.data);
      if (res.data) {
          if (res.data.type == "DeviceMeasurement") {
            commit('setDeviceTypeCard', 'DeviceMeasurement');
            
          } 
          else if (res.data.type == "WeatherObserved"){
            commit('setDeviceTypeCard', 'WeatherObserved');
          }
          else if (res.data.type == "Energia_Mono" || res.data.type == "Energia_Tri"){
            commit('setDeviceTypeCard', 'Energy');
          }
          else if (res.data.type == "THSensor"){
            commit('setDeviceTypeCard', 'THSensor');
          }
          else if (res.data.type == "Motion"){
            commit('setDeviceTypeCard', 'Motion');
          }
          else {
            commit('setDeviceTypeCard', 'Water');
          }

          if (res.data.location != undefined) { // filtra posibles casos de dispositivos registrados que no tengan un location establecido
            res.data.location.coordinates = res.data.location.coordinates.reverse();
          }

        commit('setDeviceCurrentData', res.data);
      }
      else{
        commit('setDeviceCurrentData', null);
        commit('setDeviceTypeCard', 'THSensor');
      }
    } catch (error) {
      console.log(error);
    }
  },
  async fetchChartSeries({ commit, getters }, payload) {
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_QUANTUMLEAP_URL}/v2/entities/${payload.dev_id}/attrs/${payload.attr_name}`, {
        params: {
          type: payload.type,
          aggrMethod: payload.aggrMethod,
          aggrPeriod: payload.aggrPeriod,
          lastN: payload.lastN
        },
        headers: {
          'Accept': 'application/json',
          'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'X-Auth-Token': authHeader().token
        },
      });
      //console.log(res.data.length);
      if (res.data) {
        if (res.data.index.length > 0) {
          
          var newData = [];
          for (let i = 0; i < res.data.index.length; i++) {
            newData.push([new Date(res.data.index[i]),res.data.values[i]]);
          }
          //console.log(newData);
          commit('setDeviceData', res.data);
          commit('setChartSeries', [{name: payload.dev_id ,data:newData}]);
        }
        else {

          commit('setDeviceData', []);
          commit('setChartSeries', [{name: payload.dev_id ,data:[]}]);
        }
      } else {

        commit('setDeviceData', []);
        commit('setChartSeries', [{name: payload.dev_id ,data:[]}]);
      }
      
    } catch (error) {
      console.log(error);
    }
  },
  async fetchChartSeriesTimePeriod({ commit, getters }, payload) {
    //console.log(payload)
    try {
      let selectedService = getters.getFiwareService;
      let res = await axios.get(`${process.env.VUE_APP_QUANTUMLEAP_URL}/v2/entities/${payload.dev_id}/attrs/${payload.attr_name}`, {
        params: {
          type: payload.type,
          aggrMethod: payload.aggrMethod,
          aggrPeriod: payload.aggrPeriod,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        },
        headers: {
          'Accept': 'application/json',
          'fiware-service': selectedService.IOT_FIWARE_SERVICE || process.env.VUE_APP_IOT_FIWARE_SERVICE,
          'fiware-servicepath': selectedService.IOT_FIWARE_SERVICEPATH || process.env.VUE_APP_IOT_FIWARE_SERVICEPATH,
          'X-Auth-Token': authHeader().token
        },
      });
      //console.log(res.data.length);
      if (res.data) {
        if (res.data.index.length > 0) {
          
          var newData = [];
          for (let i = 0; i < res.data.index.length; i++) {
            newData.push([new Date(res.data.index[i]),res.data.values[i]]);
          }
          //console.log(newData);
          commit('setDeviceData', res.data);
          commit('setChartSeries', [{name: payload.dev_id ,data:newData}]);
        }
        else {

          commit('setDeviceData', []);
          commit('setChartSeries', [{name: payload.dev_id ,data:[]}]);
        }
      } else {

        commit('setDeviceData', []);
        commit('setChartSeries', [{name: payload.dev_id ,data:[]}]);
      }
      
    } catch (error) {
      console.log(error);
    }
  },
  async checkOrionService({ commit }) {
    try {
      let res = await axios.get(`${process.env.VUE_APP_CONTEXT_BROKER_URL}/version`);
      //console.log(res.data);
      commit('setServiceCheck', res.data);
    } catch (error) {
      console.log(error);
      commit('setServiceCheck', null);
    }
  },
  async checkIoTAgentService({ commit }) {
    try {
      let res = await axios.get(`${process.env.VUE_APP_IOT_AGENT_URL}/iot/about`);
      //console.log(res.data);
      commit('setServiceCheck', res.data);
    } catch (error) {
      console.log(error);
      commit('setServiceCheck', null);
    }
  },
  async checkQuantumleapService({ commit }) {
    try {
      let res = await axios.get(`${process.env.VUE_APP_QUANTUMLEAP_URL}/version`);
      //console.log(res.data);
      commit('setServiceCheck', res.data);
    } catch (error) {
      console.log(error);
      commit('setServiceCheck', null);
    }
  },

};

const mutations = {
  setDeviceTypeCard: (state, deviceTypeCard) => (state.deviceTypeCard = deviceTypeCard),
  setProvisionedDevice: (state, provisionedDevice) => (state.provisionedDevice = provisionedDevice),
  setProvisionedDevices: (state, provisionedDevices) => (state.provisionedDevices = provisionedDevices),
  setProvisionedServices: (state, provisionedServices) => (state.provisionedServices = provisionedServices),
  setContextDevices: (state, devices) => (state.devices = devices),
  setContextDevicesWithEntity: (state, devicesWithEntity) => (state.devicesWithEntity = devicesWithEntity),
  setIotEntity: (state, iotEntity) => (state.iotEntity = iotEntity),
  setIotEntities: (state, iotEntities) => (state.iotEntities = iotEntities),
  setEntities: (state, entities) => (state.entities = entities),
  setIotSubscription: (state, iotSubscription) => (state.iotSubscription = iotSubscription),
  setIotSubscriptions: (state, iotSubscriptions) => (state.iotSubscriptions = iotSubscriptions),
  setDevicesCurrentData: (state, devicesCurrentData) => (state.devicesCurrentData = devicesCurrentData),
  setDeviceCurrentData: (state, deviceCurrentData) => (state.deviceCurrentData = deviceCurrentData),
  setDeviceData: (state, deviceData) => (state.deviceData = deviceData),
  setChartSeries: (state, chartSeries) => (state.chartSeries = chartSeries),
  setServiceCheck: (state, serviceCheck) => (state.serviceCheck = serviceCheck),
  setFiwareService: (state, fiwareService) => (state.fiwareService = fiwareService),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
