import axios from 'axios';
import {AES, enc} from 'crypto-js';
import {Buffer} from 'buffer';
import authHeader from '@/utils/auth-header';

// Encode Authorization header
const key = `${process.env.VUE_APP_KEYROCK_CLIENT_ID}:${process.env.VUE_APP_KEYROCK_CLIENT_SECRET}`;
const encodedAuth = new Buffer.from(key).toString('base64');
//console.log(encodedAuth)

const state = {
  token: null,
  user: {},
  users: [],
  signedIn: false,
  error: null,
};

const getters = {
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getUsers: (state) => state.users,
  signedIn: (state) => state.signedIn,
  getError: (state) => state.error,
};

const actions = {
  async listUser({ commit }) {
    try {
      let res = await axios.get(`users`, {
        headers: {
          Authorization: authHeader().token,
        },
      });
      //console.log(res.data);

      commit('setUsers', res.data);
    } catch (error) {
      console.log(error);
    }
  },
  registerUser({ commit }, user) {
    user.role = '5f4d16c7ba1d9f1d089cf34b'; //guest role
    return new Promise((resolve, reject) => {
      axios
        .post(`users`, user, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader().token,
          },
        })
        .then(({ data, status }) => {
          //console.log(data, status);
          if (status === 201) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async authUser({ commit, state }, login) {
    try {
      
    login = `username=${login.username}&password=${login.password}&grant_type=password`
    var res = await axios.post(`${process.env.VUE_APP_KEYROCK_URL}/oauth2/token`, login, {
          headers: {
            'Authorization': `Basic ${encodedAuth}`,
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        });
        if (res.status == 200) {
          //console.log(res.data);
          commit('setToken', res.data.access_token);
          let expirationInMin = process.env.VUE_APP_SESION_EXPIRATION_MIN;
          let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin));
          let token = {
            value: res.data.access_token,
            expirationDate: expirationDate.toISOString()
          };
          const encryptedToken = AES.encrypt(JSON.stringify(token),process.env.VUE_APP_CRYPTO_KEY).toString();
          sessionStorage.setItem('access_token', encryptedToken);
          //get user datails
          res = await axios.get(`${process.env.VUE_APP_KEYROCK_URL}/user?access_token=${state.token}`)
          if (res.status == 200){
            //console.log(res.data)
            //user tiene roles asignado?
            if (res.data.roles.length > 0) {            
              commit('setUser', res.data);
              const encryptedUser = AES.encrypt(JSON.stringify(res.data),process.env.VUE_APP_CRYPTO_KEY).toString();
              sessionStorage.setItem('user', encryptedUser);
              commit('setSignedIn', true);
            }
            else{
              commit('setToken', null);
              commit('setUser', null);
              commit('setSignedIn', false);
              commit('setError', null);
              sessionStorage.removeItem('user');
              sessionStorage.removeItem('access_token');             
            }
          }
        }
      
  
      } catch (error) {
        console.log(error);
        commit('setError', error);
          reject(error);
      }
  },
  logOut({ commit }) {
    commit('setToken', null);
    commit('setUser', null);
    commit('setSignedIn', false);
    commit('setError', null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
  },
};

const mutations = {
  setToken: (state, token) => (state.token = token),
  setUser: (state, user) => (state.user = user),
  setUsers: (state, users) => (state.users = users),
  setSignedIn: (state, signedIn) => (state.signedIn = signedIn),
  setError: (state, error) => (state.error = error),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
