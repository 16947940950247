import {AES, enc} from 'crypto-js';
export default function authHeader() {
  //console.log('authHeader');
  var token = sessionStorage.getItem('access_token');
  var user = sessionStorage.getItem('user');
  if (token && user) {
  const decryptedToken = AES.decrypt(sessionStorage.getItem('access_token'),process.env.VUE_APP_CRYPTO_KEY);
  const decryptedUser = AES.decrypt(sessionStorage.getItem('user'),process.env.VUE_APP_CRYPTO_KEY);
  token = JSON.parse(decryptedToken.toString(enc.Utf8));
  user = JSON.parse(decryptedUser.toString(enc.Utf8));
  
  if (user && token) {
    
    let expirationDate = new Date(token.expirationDate)
    if (expirationDate > new Date()) {
      return {user:user, token:token.value}; // for Spring Boot back-end
      //return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else { // el token expiro forza inicio de sesion
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('access_token'); 
      location.href = "/";
    }

  } else {
    return {user:null, token:null};
  }
}
else {
  return {user:null, token:null};
}
}
